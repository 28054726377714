import { ASSET_PERMISSIONS_BY_ACCESS_TYPE } from '@dtx-company/inter-app/src/components/FlowTeams/components/constants/asset-permissions.constants'
import {
  AssetPermission,
  CollaboratorAccess,
  PermissionName
} from '@dtx-company/inter-app/src/components/FlowTeams/types/asset-permissions.types'
import { EntityTypes } from '@dtx-company/inter-app/src/redux/slices/modal'
import { PermissionsTypes } from '@dtx-company/inter-app/src/types/flowcode'
import {
  UseGetPermissionsProps,
  UseGetPermissionsReturnType
} from '@dtx-company/inter-app/src/types/permissions'
import { fetchPermissions } from './useGetPermissions.fetcher'
import { getPermissionsName } from '../utils/permissions'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { useMemo } from 'react'
import useSWR from 'swr'

export function useGetPermissions({
  batchId,
  directoryId,
  isBatch,
  pageId,
  shouldFetch = true,
  subdomainId,
  pageTemplateId
}: UseGetPermissionsProps): UseGetPermissionsReturnType {
  const { jwt } = useAuthState()
  const thisPage = useCurrentPage(pageId)

  const assetIdObj = useMemo(
    () => ({ batchId, directoryId, pageId, subdomainId, pageTemplateId }),
    [batchId, directoryId, pageId, subdomainId, pageTemplateId]
  )

  const hasAssetId = Boolean(batchId || directoryId || pageId || subdomainId || pageTemplateId)
  const fetchNow = Boolean(shouldFetch && hasAssetId && jwt)
  const swrKey = fetchNow ? [assetIdObj, isBatch, jwt, thisPage] : null
  const { data, isLoading, error } = useSWR(swrKey, fetchPermissions)

  const { isOwner, entityPermissions, existingCollaborators, ownerFullName, ownerEntityId } =
    data ?? {
      isOwner: false,
      entityPermissions: [],
      existingCollaborators: [],
      ownerFullName: '',
      ownerEntityId: ''
    }

  const permissionName = useMemo(
    () => (isOwner ? PermissionsTypes.CREATOR : getPermissionsName(isOwner, entityPermissions)),
    [isOwner, entityPermissions]
  )
  const viewOnly = pageId ? !(isOwner || permissionName === PermissionsTypes.EDITOR) : !isOwner

  const allPermissions = useMemo<AssetPermission[]>(() => {
    const ownerPermission: AssetPermission = {
      entityId: ownerEntityId,
      entityName: ownerFullName,
      entityType: EntityTypes.USER,
      permissionName: PermissionName.OWNER
    }

    return [
      ownerPermission,
      ...existingCollaborators
        .filter(c => c.entityId !== ownerEntityId)
        .map(c => ({
          entityId: c.entityId,
          entityName: c.name,
          entityType: typeof c.entityType === 'string' ? parseInt(c.entityType) : c.entityType,
          permissionName: ASSET_PERMISSIONS_BY_ACCESS_TYPE[c.access as CollaboratorAccess]
        }))
    ]
  }, [existingCollaborators, ownerEntityId, ownerFullName])

  return {
    allPermissions,
    existingCollaborators,
    permissionName,
    ownerEntityId,
    ownerFullName,
    viewOnly,
    isOwner,
    loading: isLoading || (!data && !error),
    error,
    entityPermissions
  }
}
