import {
  ALL_SHARED_PAGE_PERMISSIONS,
  BATCH_PERMISSIONS_DETAIL_QUERY,
  DELETE_ASSET_PERMISSIONS,
  DIRECTORY_PERMISSIONS_DETAIL_QUERY,
  PAGE_PERMISSION,
  SHARE_ASSET_PERMISSIONS,
  STUDIO_CONFIG_ENTITY_PERMISSIONS_DETAIL_QUERY
} from '../gql/queries/permissions'
import { AUTHENTICATION_V2_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import {
  AssetPermission,
  AssetType,
  PermissionName
} from '@dtx-company/inter-app/src/components/FlowTeams/types/asset-permissions.types'
import {
  AssetTypes,
  MutationDeleteAssetPermissionArgs,
  MutationShareAssetPermissionArgs,
  PageIdPermissionMap,
  QueryGetAllSharedPagePermissionsArgs,
  ShareAssetsMutation,
  ShareAssetsMutationVariables,
  SharedAssetPermissions,
  UnshareAssetsMutation,
  UnshareAssetsMutationVariables
} from '@dtx-company/flow-codegen/src/page/generated.types'
import {
  COLLABORATORS_BACKGROUND_IMAGES,
  CREATE_ASSET_PERMISSION_ERROR,
  GET_ASSET_PERMISSION_ERROR,
  NON_ALPHA_NUMERIC_REGEX,
  REMOVE_ASSET_PERMISSION_ERROR,
  UPDATE_ASSET_PERMISSION_ERROR,
  permissionsNames
} from '../constants/permissions'
import {
  CollaboratorsType,
  EntityPermissionsType,
  FetchCollboratorsResponseType,
  GetAssetPermissionType,
  GetBatchPermissionsProps,
  GetBatchPermissionsResponseType,
  GetCollboratorsResponseType,
  GetDirectoryPermissionsProps,
  GetDirectoryPermissionsResponseType,
  GetStudioConfigEntityPermissionsProps,
  GetStudioConfigEntityPermissionsResponseType,
  PermissionsValueTypes,
  UpdateAndRemovePermissions
} from '@dtx-company/inter-app/src/types/permissions'
import { EntityTypes } from '@dtx-company/inter-app/src/redux/slices/modal'
import { PermissionsTypes } from '@dtx-company/inter-app/src/types/flowcode'
import {
  SHARE_ASSETS_MUTATION,
  UNSHARE_ASSETS_MUTATION
} from '@dtx-company/flow-codegen/src/page/mutations'
import {
  SHARE_CODE_SUBDOMAIN,
  UNSHARE_CODE_SUBDOMAIN
} from '@dtx-company/flow-codegen/src/code/mutations'
import {
  ShareIthacaAssetsMutation,
  ShareIthacaAssetsMutationVariables,
  UnshareIthacaAssetsMutation,
  UnshareIthacaAssetsMutationVariables
} from '@dtx-company/flow-codegen/src/code/generated.types'
import { gqlFetcher, pageGqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'
import { pageQueries } from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/pageQueries'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

export const getRandomBackgroundImage = (): string => {
  const num = Math.floor(Math.random() * COLLABORATORS_BACKGROUND_IMAGES.length)
  return COLLABORATORS_BACKGROUND_IMAGES[num]
}

export const getNameInitials = (fullName: string): string => {
  if (!fullName) return ''
  const nameInitials = fullName
    .split(' ')
    .map(name => name.charAt(0).toUpperCase())
    .join('')
  return nameInitials.replace(NON_ALPHA_NUMERIC_REGEX, '').substring(0, 2) || ''
}

export const getTeamOrgInitials = (name: string): string => name.charAt(0).toUpperCase() || ''

export function getCodeCreatorData(
  fullName = '',
  ithacaId = '',
  isOwner = true
): CollaboratorsType[] {
  return [
    {
      name: `${fullName}${isOwner ? ' (you)' : ''}`,
      entityId: ithacaId,
      entityType: EntityTypes.USER,
      icon: getRandomBackgroundImage(),
      access: PermissionsTypes.CREATOR,
      initials: getNameInitials(fullName)
    }
  ]
}

/**
 * REST
 * @todo: move to SWR - Kav
 */

export async function getCollaborators(
  token: string
): Promise<GetCollboratorsResponseType | undefined> {
  try {
    const response = await fetch(`${AUTHENTICATION_V2_ENDPOINT}/collaborators`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
    if (response.status !== 200) throw new Error('error fetching collaborators')
    const responseJSON = await response.json()
    const formatOrgMembersCollaborators: CollaboratorsType[] = responseJSON.orgMembers?.map(
      (member: FetchCollboratorsResponseType) => {
        const fullName = `${member.firstName} ${member.lastName}`.trim()
        return {
          name: fullName,
          email: member.email,
          entityId: member.ithacaId,
          entityType: EntityTypes.USER,
          icon: getRandomBackgroundImage(),
          access: PermissionsTypes.VIEWER,
          initials: getNameInitials(fullName),
          role: member.orgRole
        }
      }
    )
    const orgName = responseJSON.org?.name || ''
    const formatOrgsCollaborators: CollaboratorsType = {
      name: orgName,
      entityId: responseJSON.org?.id,
      entityType: EntityTypes.ORG,
      icon: getRandomBackgroundImage(),
      access: PermissionsTypes.VIEWER,
      initials: getTeamOrgInitials(orgName)
    }
    const formatTeamsCollaborators: CollaboratorsType[] = responseJSON.teams?.map(
      (team: FetchCollboratorsResponseType) => {
        const teamName = team.name || ''
        return {
          name: teamName,
          entityId: team.id,
          entityType: EntityTypes.TEAM,
          icon: getRandomBackgroundImage(),
          access: PermissionsTypes.VIEWER,
          initials: getTeamOrgInitials(teamName)
        }
      }
    )
    return {
      collaborators: [...formatOrgMembersCollaborators, ...formatTeamsCollaborators],
      org: formatOrgsCollaborators
    }
  } catch (e) {
    console.error('getCollaborators: ', e)
  }
}

export const getAssetPermissions = async (
  assetId: string | number,
  ithacaId: string | number,
  token: string | undefined
): Promise<GetAssetPermissionType[]> => {
  const res = await fetch(
    `${AUTHENTICATION_V2_ENDPOINT}/asset-permissions?ithacaId=${ithacaId}&assetId=${assetId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  )

  if (res.status === 200) {
    const result = await res.json()
    return result?.data as GetAssetPermissionType[]
  } else {
    console.error('getAssetPermissions', res)
    throw { code: GET_ASSET_PERMISSION_ERROR.code }
  }
}

export const createAssetPermissions = async (
  permissions: AssetPermission[],
  assetId: string | number,
  token: string | undefined
): Promise<void> => {
  const res = await fetch(`${AUTHENTICATION_V2_ENDPOINT}/asset-permissions`, {
    method: 'POST',
    body: JSON.stringify({
      assetId,
      entityPermissions: permissions
    }),
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  if (res.status === 200) return res.json()
  else {
    console.error('createAssetPermissions', res)
    throw { code: CREATE_ASSET_PERMISSION_ERROR.code }
  }
}

export const updateAssetPermissions = async (
  permissions: AssetPermission[],
  assetId: string | string[],
  token: string
): Promise<void> => {
  const entityPermissions = permissions.map(p => {
    return {
      entityId: p.entityId,
      entityType: Number(p.entityType),
      permissionName: p.permissionName
    }
  })
  const res = await fetch(`${AUTHENTICATION_V2_ENDPOINT}/asset-permissions`, {
    method: 'POST',
    body: JSON.stringify({
      assetId,
      entityPermissions
    }),
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
  if (res.status === 200) return res.json()
  else {
    console.error('updateAssetPermissions', res)
    throw { code: UPDATE_ASSET_PERMISSION_ERROR.code }
  }
}

export const updateSubdomainAssetPermissions = async (
  permissions: AssetPermission[],
  assetId: string,
  isCodeSubdomain: boolean
): Promise<void> => {
  const entityPermissions = permissions.map(p => {
    return {
      entityId: p.entityId,
      entityType: Number(p.entityType),
      permissionName: p.permissionName
    }
  })
  if (isCodeSubdomain) {
    try {
      const result = await gqlFetcher<
        ShareIthacaAssetsMutation,
        ShareIthacaAssetsMutationVariables
      >(SHARE_CODE_SUBDOMAIN, {
        assetType: 'fc_white_label',
        assetIds: [assetId],
        entityPermissions
      })
      if (!result.shareIthacaAssets?.assetPermissions?.[0]?.result?.success) {
        throw new Error('There was a problem sharing this subdomain')
      }
    } catch (e) {
      console.error('Error sharing whitelabel subdomain', e)
    }
  } else {
    try {
      const result = await pageGqlFetcher<ShareAssetsMutation, ShareAssetsMutationVariables>(
        SHARE_ASSETS_MUTATION,
        { assetType: AssetTypes.WhiteLabel, assetIds: [assetId], entityPermissions }
      )
      if (!result.shareAssets?.[0]?.result?.success) {
        throw new Error('There was a problem sharing this subdomain')
      }
    } catch (e) {
      console.error('Error sharing page subdomain', e)
    }
  }
}

export const getPermissionsName = (
  isOwner: boolean,
  entityPermissions?: EntityPermissionsType[] | null
): string | undefined => {
  if (isOwner) {
    return permissionsNames[PermissionsValueTypes.Creator]
  } else if (entityPermissions) {
    let isEditor = false
    for (let i = 0; i < entityPermissions.length; i++) {
      const permission = entityPermissions[i]
      if (permission.permissionName === PermissionsValueTypes.Editor) {
        isEditor = true
        break
      }
    }

    return isEditor
      ? permissionsNames[PermissionsValueTypes.Editor]
      : permissionsNames[PermissionsValueTypes.Viewer]
  } else {
    return undefined
  }
}

export const getPermissionsType = (
  entityPermissions?: EntityPermissionsType[] | null
): number | undefined => {
  if (entityPermissions && entityPermissions?.length > 0) {
    return entityPermissions[0].entityType
  } else {
    return undefined
  }
}

export const isViewPermissionType = (
  isOwner: boolean,
  entityPermissions?: EntityPermissionsType[] | null
): boolean => {
  return (
    getPermissionsName(!!isOwner, entityPermissions) ===
    permissionsNames[PermissionsValueTypes.Viewer]
  )
}

export const isRemoveablePermissionType = (
  isOwner: boolean,
  entityPermissions?: EntityPermissionsType[] | null
): boolean => {
  if (!entityPermissions) {
    return false
  }
  const isEditorOrViewer =
    getPermissionsName(!!isOwner, entityPermissions) ===
      permissionsNames[PermissionsValueTypes.Editor] ||
    getPermissionsName(!!isOwner, entityPermissions) ===
      permissionsNames[PermissionsValueTypes.Viewer]

  return isEditorOrViewer && String(entityPermissions[0]?.entityType || '') === '0'
}

export const useRemoveAssetPermission = (): (({
  entityId,
  assetId,
  token
}: {
  entityId: string | null
  assetId: string
  token: string
}) => Promise<void>) => {
  return async ({ entityId, assetId, token }): Promise<void> => {
    if (!entityId) {
      throw {
        code: REMOVE_ASSET_PERMISSION_ERROR.code,
        message: REMOVE_ASSET_PERMISSION_ERROR.message
      }
    }
    const res = await fetch(`${AUTHENTICATION_V2_ENDPOINT}/asset-permissions`, {
      method: 'DELETE',
      body: JSON.stringify({
        assetId,
        entityId
      }),
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    if (res.status === 200) return res.json()
    else {
      console.error('removeAssetPermission', res)
      throw {
        code: REMOVE_ASSET_PERMISSION_ERROR.code,
        message: REMOVE_ASSET_PERMISSION_ERROR.message
      }
    }
  }
}

export const useRemoveSubdomainAssetPermissions = (): (({
  entityId,
  assetId,
  isCodeSubdomain
}: {
  entityId: string | null
  assetId: string
  isCodeSubdomain: boolean
}) => Promise<void>) => {
  return async ({ entityId, assetId, isCodeSubdomain }): Promise<void> => {
    if (!entityId) {
      throw new Error('Cannot unshare subdomain with no entityId')
    }
    if (isCodeSubdomain) {
      try {
        const result = await gqlFetcher<
          UnshareIthacaAssetsMutation,
          UnshareIthacaAssetsMutationVariables
        >(UNSHARE_CODE_SUBDOMAIN, {
          assetType: 'fc_white_label',
          assetIds: [assetId],
          entityIds: [entityId]
        })
        if (!result?.unshareIthacaAssets?.assetPermissions?.[0]?.result?.success) throw new Error()
      } catch (e) {
        console.error('Error unsharing Flowcode subdomain', e)
        throw new Error('Cannot unshare subdomain')
      }
    } else {
      try {
        const result = await pageGqlFetcher<UnshareAssetsMutation, UnshareAssetsMutationVariables>(
          UNSHARE_ASSETS_MUTATION,
          { assetType: AssetTypes.WhiteLabel, assetIds: [assetId], entityIds: [entityId] }
        )
        if (!result?.unshareAssets?.[0]?.result?.success) throw new Error()
      } catch (e) {
        console.error('Error unsharing Flowpage subdomain', e)
        throw new Error('Cannot unshare subdomain')
      }
    }
  }
}

export function useUpdateAndRemoveCollaborators(): UpdateAndRemovePermissions {
  const removeAssetPermission = useRemoveAssetPermission()
  return useCallback<UpdateAndRemovePermissions>(
    async ({ permissionsToRemove, permissionsToUpsert, assetId, token }) => {
      if (permissionsToUpsert.length) {
        await updateAssetPermissions(permissionsToUpsert, assetId, token)
      }
      await Promise.all(
        permissionsToRemove.map(async removal =>
          removeAssetPermission({ entityId: removal.entityId, assetId, token })
        )
      )
    },
    [removeAssetPermission]
  )
}

export function useUpdateAndRemoveSubdomainCollaborators(): UpdateAndRemovePermissions {
  const removeSubdomainPermission = useRemoveSubdomainAssetPermissions()
  return useCallback<UpdateAndRemovePermissions>(
    async ({ permissionsToRemove, permissionsToUpsert, assetId, token, isCodeSubdomain }) => {
      if (permissionsToUpsert.length) {
        await updateSubdomainAssetPermissions(
          permissionsToUpsert,
          assetId,
          isCodeSubdomain ?? false
        )
      }
      await Promise.all(
        permissionsToRemove.map(async removal =>
          removeSubdomainPermission({
            entityId: removal.entityId,
            assetId,
            isCodeSubdomain: isCodeSubdomain ?? false
          })
        )
      )
    },
    [removeSubdomainPermission]
  )
}

export function useUpdateAndRemovePageCollaborators(): UpdateAndRemovePermissions {
  const dispatch = useDispatch()
  return useCallback<UpdateAndRemovePermissions>(
    async ({ permissionsToRemove, permissionsToUpsert, assetId }) => {
      const requests: Promise<PageIdPermissionMap[]>[] = []

      for (const p of permissionsToUpsert) {
        await requests.push(
          sharePagePermissions({
            pageIds: [assetId],
            entityId: p.entityId,
            entityType: p.entityType,
            sharedPermission:
              p.permissionName === PermissionName.EDITOR
                ? SharedAssetPermissions.SharedPermissionsEditor
                : SharedAssetPermissions.SharedPermissionsViewer
          })
        )
      }

      for (const p of permissionsToRemove) {
        await requests.push(deletePagePermissions({ entityId: p.entityId, pageIds: [assetId] }))
      }
      await Promise.all(requests)
      dispatch(
        pageQueries.util.updateQueryData('pageManagement', {}, currentState => {
          Object.assign(currentState, {
            ...currentState,
            me: {
              ...currentState.me,
              pageConnection: {
                ...currentState.me.pageConnection,
                edges: currentState.me.pageConnection.edges.filter(({ id }) => id === assetId)
              }
            }
          })
        })
      )
    },
    [dispatch]
  )
}

export const getBatchPermissions = async (
  variables: GetBatchPermissionsProps,
  token: string
): Promise<GetBatchPermissionsResponseType> => {
  return gqlFetcher(BATCH_PERMISSIONS_DETAIL_QUERY, variables, false, token)
}

export const getDirectoryPermissions = async (
  variables: GetDirectoryPermissionsProps
): Promise<GetDirectoryPermissionsResponseType> => {
  return gqlFetcher(DIRECTORY_PERMISSIONS_DETAIL_QUERY, variables)
}

export const getStudioConfigEntityPermissions = async (
  variables: GetStudioConfigEntityPermissionsProps
): Promise<GetStudioConfigEntityPermissionsResponseType> => {
  return gqlFetcher(STUDIO_CONFIG_ENTITY_PERMISSIONS_DETAIL_QUERY, variables)
}

export const getPagePermissions = async (
  variables: QueryGetAllSharedPagePermissionsArgs
): Promise<EntityPermissionsType[]> => {
  const data = await pageGqlFetcher(PAGE_PERMISSION, variables)
  const permission = getPermissionFromResponse(data)

  return permission ? [permission] : []
}

export const getAllSharedPagePermissions = async (
  variables: QueryGetAllSharedPagePermissionsArgs
): Promise<EntityPermissionsType[]> => {
  const data = await pageGqlFetcher<{ getAllSharedPagePermissions: EntityPermissionsType[] }>(
    ALL_SHARED_PAGE_PERMISSIONS,
    variables
  )
  return data?.getAllSharedPagePermissions || []
}

function getPermissionFromResponse(data: unknown): EntityPermissionsType | undefined {
  if (!isObject(data) || !('Page' in data) || !isObject(data.Page) || !('permission' in data.Page))
    return

  const permission = data.Page.permission
  if (!isEntityPermissions(permission)) return

  return permission
}

const isEntityPermissions = (value: unknown): value is EntityPermissionsType => {
  return (
    isObject(value) &&
    'entityId' in value &&
    'entityName' in value &&
    'entityType' in value &&
    'permissionName' in value
  )
}

function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null
}

export const sharePagePermissions = async (
  variables: MutationShareAssetPermissionArgs
): Promise<Array<PageIdPermissionMap>> => {
  return pageGqlFetcher(SHARE_ASSET_PERMISSIONS, variables)
}

export const deletePagePermissions = async (
  variables: MutationDeleteAssetPermissionArgs
): Promise<Array<PageIdPermissionMap>> => {
  return pageGqlFetcher(DELETE_ASSET_PERMISSIONS, variables)
}

export function formatAssetId({
  assetType,
  assetId
}: {
  assetId?: string
  assetType: AssetType
}): string {
  return `${assetType}:${assetId}`
}
